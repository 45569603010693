<template>
  <div>
    <ClientOnly>
      <CommonSkeletonLoader v-if="isLoading" class="h-full w-full" />

      <img
        v-show="!isLoading && imageExists"
        class="h-full w-full object-fill"
        :class="imgClasses"
        :src="src"
        :alt="alt"
        @error="handleImageError"
        @load="handleLoad"
      />

      <img
        v-if="!isLoading && !imageExists"
        class="h-full w-full bg-grey-lightest object-fill"
        src="~/assets/images/no-photo.png"
        :class="imgClasses"
        :alt="alt"
      />
      <template #fallback>
        <CommonSkeletonLoader class="h-full w-full" />
      </template>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
defineProps({
  src: {
    type: String,
  },
  imgClasses: {
    type: String,
  },
  alt: {
    type: String,
  },
});
const isLoading = ref(true);
const imageExists = ref(true);

// Event handler for image error
const handleImageError = () => {
  isLoading.value = false;
  imageExists.value = false;
};

const handleLoad = () => {
  isLoading.value = false;
};
</script>
